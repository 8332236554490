import "./styles.css";
import { Difficulty, getPuzzle } from "./lib/sudoku";
import { getPossibleAnswers } from "./lib/possibilities";
import {
  initialiseTimer,
  pauseTimer,
  resetTimer,
  resumeTimer,
} from "./lib/timer";

const GRID_SIZE = 9;

let sudokuData: SudokuData;
let selectedCell: HTMLElement | null = null;
let noteMode = false;
let currentNoteMode = "default";
let showPossibilitiesMode = false;
let userSolution: UserSolution[];
let numPadClicks: { [number: string]: number } = {};

function prefillNumPadClicks() {
  numPadClicks = {};

  userSolution.forEach((cell) => {
    if (cell.answer !== "-") {
      increaseNumPadValue(cell.answer);
    }
  });
}

function createSudokuGrid(
  containerId: string,
  puzzle: string,
  userSolution: UserSolution[],
) {
  const container = document.getElementById(containerId);

  prefillNumPadClicks();

  if (!container) {
    alert("Container not found");
    return;
  }

  container.innerText = ""; // Clear previous grid

  for (let index = 0; index < puzzle.length; index++) {
    const cell = document.createElement("div");
    cell.classList.add("cell");

    const row = Math.floor(index / 9);
    const col = index % 9;

    // Thick borders for 3x3 blocks
    if ((col + 1) % 3 === 0 && col !== 8) {
      cell.classList.add("thick-right");
    }
    if ((row + 1) % 3 === 0 && row !== 8) {
      cell.classList.add("thick-bottom");
    }

    // restoreNotesToCells(cell, index);

    // Set content and attributes based on the puzzle and userSolution
    if (puzzle[index] !== "-") {
      cell.textContent = puzzle[index];
      cell.classList.add("readonly");

      restoreNotesToCells(cell, index);
    } else {
      cell.setAttribute("contenteditable", "false");

      if (userSolution[index].answer !== "-") {
        cell.textContent = userSolution[index].answer;
      } else {
        restoreNotesToCells(cell, index);
      }

      cell.setAttribute("contenteditable", "false");
      cell.addEventListener("click", () => {
        if (selectedCell) {
          selectedCell.classList.remove("selected");
        }
        selectedCell = cell;
        cell.classList.add("selected");
      });
    }

    container.appendChild(cell);
  }
}

// Checks if there is a puzzle already ongoing in the local storage
function loadPuzzle() {
  const puzzle = localStorage.getItem("puzzle");

  if (puzzle) {
    const loadedData = JSON.parse(puzzle);

    sudokuData = loadedData.sudokuData;
    userSolution = loadedData.userSolution.map((cell: UserSolution) => ({
      answer: cell.answer,
      notes: cell.notes || {},
    }));

    return true;
  }

  return false;
}

// Saves the puzzle in the local storage
function savePuzzle() {
  localStorage.setItem("puzzle", JSON.stringify({ sudokuData, userSolution }));
}

function clearPuzzle() {
  localStorage.removeItem("puzzle");
}

function increaseNumPadValue(value: string) {
  if (numPadClicks[value]) {
    numPadClicks[value]++;
  } else {
    numPadClicks[value] = 1;
  }

  const numpadButton = document.querySelector(`[data-value="${value}"]`);

  if (numPadClicks[value] >= 9) {
    numpadButton?.classList.add("disabled");
  } else {
    numpadButton?.classList.remove("disabled");
  }
}

function decreaseNumPadValue(value: string) {
  if (numPadClicks[value]) {
    numPadClicks[value]--;
  } else {
    numPadClicks[value] = 0;
  }

  const numpadButton = document.querySelector(`[data-value="${value}"]`);

  if (numPadClicks[value] >= 9) {
    numpadButton?.classList.add("disabled");
  } else {
    numpadButton?.classList.remove("disabled");
  }
}

function handleNumpadClick(event: Event) {
  const target = event.target as HTMLElement;
  const value = target.getAttribute("data-value");

  // Disallow to edit values that are already preset by the puzzle
  if (!value || selectedCell?.classList.contains("readonly")) {
    return;
  }

  if (selectedCell) {
    const index = Array.prototype.indexOf.call(
      selectedCell.parentNode?.children,
      selectedCell,
    );

    if (value === "delete") {
      if (userSolution[index].answer !== "-") {
        decreaseNumPadValue(userSolution[index].answer);
      }

      selectedCell.textContent = "";
      userSolution[index].answer = "-";

      restoreNotesToCells(selectedCell, index);
    } else {
      if (noteMode) {
        addNoteToCell(selectedCell, value, index);
      } else {
        if (!numPadClicks[value] || numPadClicks[value] < 9) {
          if (userSolution[index].answer !== value) {
            const currentValue = userSolution[index].answer;

            if (currentValue !== "-") {
              decreaseNumPadValue(userSolution[index].answer);
            }

            // Remove the notes from the cell
            userSolution[index].notes = {};

            selectedCell.textContent = value;
            userSolution[index].answer = value;

            increaseNumPadValue(value);
          }
        }
      }
    }

    savePuzzle();
    checkSolution(false);
  }
}

function restoreNotesToCells(cell: HTMLElement, index: number) {
  // Clear all existing notes in the cell
  cell.querySelectorAll(".note").forEach((note) => note.remove());

  // Render notes only for the active mode
  const notes = userSolution[index].notes[currentNoteMode] || [];

  notes.forEach((value) => {
    const note = document.createElement("div");
    note.classList.add("note", `note-${value}`, `mode-${currentNoteMode}`);
    note.textContent = value;

    cell.appendChild(note);
  });
}

function switchNoteMode(mode: string) {
  currentNoteMode = currentNoteMode === mode ? "default" : mode;
  noteMode = currentNoteMode !== "default";

  const modeButtons = document.querySelectorAll(".note-mode-button");

  modeButtons.forEach((button) => {
    if (button.getAttribute("data-mode") === currentNoteMode) {
      button.classList.add("active");
    } else {
      button.classList.remove("active");
    }
  });

  // Refresh notes for the selected mode
  refreshNotesForMode();
}

function showPossibilities(possibilitiesArray?: string[][]) {
  if (!possibilitiesArray) {
    // Remove the hide class of all notes
    const notes = document.querySelectorAll(".note");

    notes.forEach((note) => {
      note.classList.remove("hide");
    });

    // Remove all .possibility elements
    const possibilities = document.querySelectorAll(".possibility");

    possibilities.forEach((possibility) => {
      possibility.remove();
    });

    return;
  }

  // Hide all notes
  const notes = document.querySelectorAll(".note");

  notes.forEach((note) => {
    note.classList.add("hide");
  });

  // Show only the possibilities
  possibilitiesArray.forEach((values, index) => {
    if (!values.length) {
      return;
    }

    const cell = document.querySelector(
      `#sudoku-container .cell:nth-child(${index + 1})`,
    );

    if (!cell) {
      return;
    }

    values.forEach((value) => {
      const possibility = document.createElement("div");

      possibility.classList.add("possibility");
      possibility.classList.add(`note-${value}`);
      possibility.textContent = value;

      cell.appendChild(possibility);
    });
  });
}

function addNoteToCell(cell: HTMLElement, value: string, index: number) {
  const notes = userSolution[index].notes[currentNoteMode] || [];
  const noteIndex = notes.indexOf(value);

  if (noteIndex === -1) {
    // Add the note if it doesn't exist
    notes.push(value);

    const note = document.createElement("div");
    note.classList.add("note", `note-${value}`, `mode-${currentNoteMode}`);
    note.textContent = value;

    cell.appendChild(note);
  } else {
    // Remove the note if it exists
    notes.splice(noteIndex, 1);

    const existingNote = cell.querySelector(
      `.note-${value}.mode-${currentNoteMode}`,
    );
    existingNote?.remove();
  }

  userSolution[index].notes[currentNoteMode] = notes;
}

function toggleShowPossibilitiesMode() {
  showPossibilitiesMode = !showPossibilitiesMode;

  const showPossibilitiesButton = document.getElementById("show-possibilities");

  if (showPossibilitiesMode) {
    showPossibilitiesButton?.classList.add("active");

    showPossibilities(getPossibleAnswers(userSolution));
    return;
  }

  showPossibilities();
  showPossibilitiesButton?.classList.remove("active");
}

function toggleNoteMode() {
  switch (currentNoteMode) {
    case "default":
      switchNoteMode("note");
      break;
    case "note":
      switchNoteMode("strategy");
      break;
    case "strategy":
      switchNoteMode("default");
      break;
    default:
      switchNoteMode("default");
      break;
  }
}

function checkSolution(interactive: boolean) {
  let correct = true;

  for (let i = 0; i < userSolution.length; i++) {
    const cell = document.querySelector(
      `#sudoku-container .cell:nth-child(${i + 1})`,
    ) as HTMLElement;

    if (userSolution[i].answer === "-") {
      correct = false;
      continue;
    }

    if (!cell.classList.contains("readonly")) {
      if (userSolution[i].answer !== sudokuData.solution[i]) {
        if (interactive) {
          cell.classList.add("incorrect");
          setTimeout(() => {
            cell.classList.remove("incorrect");
          }, 5000);
        }

        correct = false;
      } else {
        cell.classList.remove("incorrect");
      }
    }
  }

  if (correct) {
    alert("Puzzle solved!");
    pauseTimer();
    clearPuzzle();

    const audio = document.getElementById("winAudio");
    (audio as HTMLAudioElement)
      ?.play()
      .catch(console.error)
      .then(() => console.log("music played"));
  }
}

function generatePuzzle(difficulty: Difficulty, resume?: boolean) {
  pauseTimer();
  const puzzle = resume ? loadPuzzle() : false;

  if (!sudokuData || !resume || !puzzle) {
    sudokuData = getPuzzle(difficulty);

    // Initialize userSolution with empty data
    userSolution = sudokuData.puzzle.split("").map((answer) => ({
      answer,
      notes: {},
    }));

    // const solution = `-${sudokuData.solution.slice(1)}`.split("");
    // userSolution = userSolution.map((answer, index) => ({
    //   answer: solution[index],
    //   notes: [],
    // }));

    savePuzzle();
    resetTimer();
  } else {
    resumeTimer();
  }

  createSudokuGrid("sudoku-container", sudokuData.puzzle, userSolution);
}

function refreshNotesForMode() {
  const cells = document.querySelectorAll("#sudoku-container .cell");

  cells.forEach((cell, index) => {
    restoreNotesToCells(cell as HTMLElement, index);
  });
}

document.addEventListener("DOMContentLoaded", () => {
  initialiseTimer("timer-display");

  const easyButton = document.getElementById("easy");
  const mediumButton = document.getElementById("medium");
  const hardButton = document.getElementById("hard");
  const expertButton = document.getElementById("expert");

  easyButton?.addEventListener("click", () => generatePuzzle("easy"));
  mediumButton?.addEventListener("click", () => generatePuzzle("medium"));
  hardButton?.addEventListener("click", () => generatePuzzle("hard"));
  expertButton?.addEventListener("click", () => generatePuzzle("expert"));

  const pauseButton = document.getElementById("pause-timer");

  pauseButton?.addEventListener("click", () => {
    if (pauseButton.textContent === "Pause") {
      pauseTimer();
      pauseButton.textContent = "Resume";
    } else {
      resumeTimer();
      pauseButton.textContent = "Pause";
    }
  });

  // Generate an initial puzzle
  generatePuzzle("easy", true);

  const numpadContainer = document.getElementById("numpad-container");
  numpadContainer?.addEventListener("click", handleNumpadClick);

  const checkButton = document.getElementById("check-solution");
  checkButton?.addEventListener("click", () => {
    checkSolution(true);
  });

  // const noteButton = document.getElementById("note-toggle");
  // noteButton?.addEventListener("click", toggleNoteMode);

  const noteButtons = document.querySelectorAll(".note-mode-button");

  noteButtons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;
      const mode = target.getAttribute("data-mode");

      if (mode) {
        switchNoteMode(mode);
      }
    });
  });

  const showPossibilities = document.getElementById("show-possibilities");
  showPossibilities?.addEventListener("click", toggleShowPossibilitiesMode);

  window.addEventListener("keydown", function (event) {
    event.preventDefault();

    if (event.key === " ") {
      toggleNoteMode();
    }

    // If the event is one of the arrow keys
    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowUp" ||
      event.key === "Backspace"
    ) {
      if (!selectedCell) {
        // Set the cell to the first div in the grid
        selectedCell = document.querySelector(".cell");
      }

      if (selectedCell) {
        if (event.key === "Backspace") {
          const deleteNumber: HTMLDivElement | null = document.querySelector(
            `[data-value="delete"]`,
          );
          deleteNumber?.click();
          return;
        }

        if (event.key === "ArrowRight") {
          const nextCell = selectedCell?.nextElementSibling;

          if (nextCell) {
            selectedCell?.classList.remove("selected");
            selectedCell = nextCell as HTMLElement;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowLeft") {
          const prevCell = selectedCell?.previousElementSibling;

          if (prevCell) {
            selectedCell?.classList.remove("selected");
            selectedCell = prevCell as HTMLElement;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowDown") {
          const currentIndex = Array.from(
            selectedCell?.parentNode?.children ?? [],
          ).indexOf(selectedCell);
          const nextIndex = currentIndex + GRID_SIZE;

          if (nextIndex < (selectedCell?.parentNode?.children?.length ?? 0)) {
            const nextCell = selectedCell?.parentNode?.children[
              nextIndex
            ] as HTMLElement;

            selectedCell?.classList.remove("selected");
            selectedCell = nextCell;
            selectedCell.classList.add("selected");
          }

          return;
        }

        if (event.key === "ArrowUp") {
          const currentIndex = Array.from(
            selectedCell?.parentNode?.children ?? [],
          ).indexOf(selectedCell);
          const prevIndex = currentIndex - GRID_SIZE;

          if (prevIndex >= 0) {
            const prevCell = selectedCell?.parentNode?.children[
              prevIndex
            ] as HTMLElement;

            selectedCell?.classList.remove("selected");
            selectedCell = prevCell;
            selectedCell.classList.add("selected");
          }

          return;
        }
      }
    }

    // Or if it is one of the numbers
    if (event.key >= "1" && event.key <= "9") {
      const numpadButton: HTMLDivElement | null = document.querySelector(
        `[data-value="${event.key}"]`,
      );
      numpadButton?.click();
    }
  });
});
