let elapsedTime = 0;
let timerInterval: number | null = null;
let timerElement: HTMLElement | null = null;

function saveTimer() {
  localStorage.setItem("timer", elapsedTime.toString());
}

function loadTimer() {
  const savedTime = localStorage.getItem("timer");
  elapsedTime = savedTime ? parseInt(savedTime, 10) : 0;
  updateTimerDisplay();
}

function updateTimerDisplay() {
  if (timerElement) {
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    timerElement.textContent = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;

    saveTimer();
  }
}

export function initialiseTimer(timerElementId: string) {
  timerElement = document.getElementById(timerElementId);
  loadTimer();
  updateTimerDisplay();
}

export function startTimer() {
  if (timerInterval) return;

  timerInterval = window.setInterval(() => {
    elapsedTime++;
    updateTimerDisplay();
  }, 1000);
}

export function pauseTimer() {
  if (timerInterval) {
    clearInterval(timerInterval);
    timerInterval = null;
    saveTimer();
  }
}

export function resetTimer() {
  pauseTimer();
  elapsedTime = 0;
  saveTimer();
  updateTimerDisplay();
  resumeTimer();
}

export function resumeTimer() {
  startTimer();
}
