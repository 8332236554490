export function getPossibleAnswers(userSolution: UserSolution[]): string[][] {
  const size = 9;
  const subgridSize = 3;

  function getRow(index: number): number {
    return Math.floor(index / size);
  }

  function getCol(index: number): number {
    return index % size;
  }

  function getSubgrid(index: number): number {
    const row = getRow(index);
    const col = getCol(index);
    return (
      Math.floor(row / subgridSize) * subgridSize +
      Math.floor(col / subgridSize)
    );
  }

  function getUsedValues(): {
    rows: Set<string>[];
    cols: Set<string>[];
    subgrids: Set<string>[];
  } {
    const rows = Array.from({ length: size }, () => new Set<string>());
    const cols = Array.from({ length: size }, () => new Set<string>());
    const subgrids = Array.from({ length: size }, () => new Set<string>());

    userSolution.forEach((cell, index) => {
      if (cell.answer !== "-") {
        const row = getRow(index);
        const col = getCol(index);
        const subgrid = getSubgrid(index);

        rows[row].add(cell.answer);
        cols[col].add(cell.answer);
        subgrids[subgrid].add(cell.answer);
      }
    });

    return { rows, cols, subgrids };
  }

  const { rows, cols, subgrids } = getUsedValues();

  return userSolution.map((cell, index) => {
    if (cell.answer !== "-") {
      return [];
    }

    const row = getRow(index);
    const col = getCol(index);
    const subgrid = getSubgrid(index);

    const usedValues = new Set<string>([
      ...rows[row],
      ...cols[col],
      ...subgrids[subgrid],
    ]);
    const possibleValues = [];

    for (let i = 1; i <= size; i++) {
      const value = i.toString();
      if (!usedValues.has(value)) {
        possibleValues.push(value);
      }
    }

    return possibleValues;
  });
}
